@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;
@use "./../../../../styles/screens.module" as screens;

.navbar {
	justify-content: center;
	margin-right: 1rem;

	&__cta-container {
		@include global.span-parent(max-content)
	}

	&-button-large {
		height: fit-content;
		border-radius: 3rem;
		min-width: 0rem;
	}

	&__share-page-button {
		@include global.span-parent(max-content);
		border-radius: 3rem;
		background-color: colors.$el-indigo-700;
		border-color: colors.$el-indigo-700;
	}

	&__app-logo {
		@include global.span-parent(2.3rem, 2.3rem);
		display: block;
	}

	&__app-name {
		@include global.generic-font-style(bold, 1.675rem, 2rem);
		@include global.span-parent(max-content);
		color: colors.$el-gray-900;
		padding-left: 0.5rem;
	}

	&__app-logo-box,
	&__app-share-logo-box {
		margin-left: 0;
		flex-basis: 0;
		flex-grow: 1;
		justify-content: flex-start;

		._spectrum-Button_7a745 {
			cursor: pointer;
		}
	}

	&__userinfo-container {
		flex-basis: 0;
		flex-grow: 1;
		justify-content: flex-end;
		font-weight: bold;
	}

	&__tabs-panel-container {
		width: 50%;

		._spectrum-TabsPanel--horizontal_093dd {
			align-items: center;
		}

		._spectrum-TabsPanel_093dd {


			._spectrum-TabsPanel-collapseWrapper_093dd {
				/* to Prevent collapse at bigger screen size */
				@include global.span-parent(75%);
				align-items: center;

				._spectrum-Tabs_093dd {
					// Non collapsed tabs styling
					justify-content: center;
					border-bottom-width: 0em;

					._spectrum-Tabs-item_093dd {
						+._spectrum-Tabs-item_093dd {
							/* Selecting non first child of tabs */
							/* Margin in between tab items */
							margin-left: 3.8125rem;
						}

						@include global.span-parent($height: 3.5rem);
						@include global.transition(border-bottom, ease-in, 200ms);
						cursor: pointer;
						/* For clearing out bottom line */
						border-bottom: none;

						/* Font color changes */
						._spectrum-Tabs-itemLabel_093dd {
							cursor: pointer;
						}

						&:hover .tab-option__text {
							color: colors.$el-dark-gray;
						}
					}

					._is-selected_093dd {
						border-bottom: 0.15rem solid colors.$el-indigo-700;
					}

					// When tabs are collapsed: Those classes
					._spectrum-Dropdown_06fc4 {
						._spectrum-Dropdown-label_06fc4 {
							/* Clearing gap between media and icon */
							margin-left: 0;
							@include global.span-parent(null, max-content);
						}

						._spectrum-Icon_8ac98 {
							/* Drop down selected icon vertical alignment */
							padding-top: 0.2rem;
						}

						._spectrum-Dropdown-chevron_06fc4 {
							/* handling dropdown chevron*/
							margin-left: 0.5rem;
							padding-top: 0.6rem;
						}
					}

					/* For clearing out bottom line */
					._spectrum-Tabs-selectionIndicator_093dd {
						display: none;
					}
				}
			}
		}

		.tab-option {
			&__text {
				/* navbar option like home/creation/media */
				@include global.generic-font-style(200, 1.5rem, 2.25rem);
				color: colors.$navbar-tab-color;

				padding-left: 0.25rem;
				vertical-align: unset;
			}

			&__bold-text {
				color: colors.$el-dark-gray;
				font-weight: 400;
			}

			&__icon {
				/* navbar option icons for home/creation/media */
				@include global.span-parent(1.6875rem !important, 1.375rem !important);
			}
		}
	}

	.gen-info-div {
		&__utility-nav-parent {
			@include global.span-parent(3.375rem, 3.375rem);
			@include global.flex(column) {
				@include global.flex-center;
			}
		}

		&__anonymous-user-icon-selected {
			fill: colors.$el-violet !important;
		}

		&__anonymous-user-icon {
			@include global.span-parent(3.5rem, 3.5rem);
			fill: colors.$el-indigo-700;
		}
	}
}

.anonymous-user-cta-text {
	@include global.generic-font-style(bold, 1.125rem, 1.875rem);
}

/* Breaking a little early for better look and feel */
@media (max-width: (screens.$ipad-pro-portrait + 56px)) {
	.navbar {
		&__tabs-panel-container {
			._spectrum-TabsPanel_093dd {
				._spectrum-TabsPanel-collapseWrapper_093dd {
					/* Collapsing hereafter */
					@include global.span-parent(25%);
				}
			}

			.tab-option {
				&__text {
					/* navbar option like home/creation/media */
					@include global.flex(column, $justify: center);
					padding-top: 0.25rem;
				}
			}
		}
	}

	/* navbar option in popover has to be out of proportion due to grid positioning*/
	._spectrum-Popover_c37f7 ._spectrum-Menu-itemGrid_7c360 {
		.tab-option {
			&__text {
				padding-top: 0.2rem;
			}
		}
	}

	/* navbar option in tray has to be out of proportion due to grid positioning*/
	._spectrum-Tray-wrapper_8bca2 ._spectrum-Menu-itemGrid_7c360 {
		.tab-option {
			&__text {
				padding-top: 0.2rem;
			}
		}
	}
}

.circle {
	position: absolute;
	top: 0.3125rem;
	left: 1.25rem;
	width: 0.625rem;
	height: 0.625rem;
	border-radius: 50%;
	background: #C64545;
}

@media (max-width: screens.$iphone-xr-landscape) {
	.navbar {
		&__tabs-panel-container {
			.tab-option {
				&__text {
					padding-top: 0.5rem;
				}
			}
		}
	}
}

@media (max-width: (screens.$moto-g4-landscape + 96px)) {
	.navbar {
		&__app-name {
			display: none;
		}

		&__app-logo-box {
			margin-left: -2.5rem;
		}

		&__app-share-logo-box {
			margin-left: -1.5rem;
		}
	}
}