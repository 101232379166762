@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;
@use "./../../../../styles/zlevel.module" as zIndex;


#replace-background-container {
    @include global.span-parent(100vw, 100%);
    @include global.fade-in(0.3s);
}    

#replace-background-creations-header-container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-bottom: 0.0625em solid #cecece;
    z-index: zIndex.$level-max;
    position: relative;
}    

.replace-background-main-content-box {
    @include global.flex();
    @include global.creation-content-box;
}

.replace-background-feedback-icon-container {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
}

#replace-background-edit-container {
    @include global.flex();
    position: relative;
    overflow: hidden;
    flex-direction: row;
    flex-grow: 1;
}

#replace-background-progress-container {
    @include global.flex() {
        @include global.flex-center;
    }

    @include global.span-parent(100%, 100%);
    @include global.generic-font-style(medium, 1.125rem, 1.875rem);
    flex-direction: row;
    top: 0;
    left: 0;
    position: absolute;
    z-index: zIndex.$level-max;
    background-color: colors.$translucent-white-high;
}

.replace-background__secondary_text {
    @include global.generic-font-style(normal, 1.2rem, 2.5rem);
    color: colors.$el-gray-700;
}