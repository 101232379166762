$creation-header-height: 4.5rem;

@mixin generic-font-style($weight, $size, $height, $family: var(--font-family), $style: normal, $variant: normal) {
    font: $style $variant $weight #{$size}/#{$height} $family;
    &:lang(ja-JP) {
        @if $style == italic {
            font: normal $variant $weight #{$size}/#{$height} $family;
        }
    }
    letter-spacing: 0px;
    opacity: 1;
    @content;
}

@mixin flex-center {
    align-items: center;
    justify-content: center;
}

@mixin creation-content-box {
    @include span-parent(100%, calc(100vh - $creation-header-height));
}

@mixin flex($direction: row, $align: null, $justify: null) {
    display: flex;
    flex-direction: $direction;
    align-items: $align;
    justify-content: $justify;
    @content;
}

@mixin grid($place-items: null) {
    display: grid;
    place-items: $place-items;
    @content;
}

@mixin span-parent($width: null, $height: null) {
    width: $width;
    height: $height;
}

// Animation Arena
@mixin fade-in($time: null) {
    animation: fadein $time;
}

@mixin fade-out($time: null) {
    animation: fadeout $time;
}

@mixin transition($css-property: null, $time: null, $animation-style: null, $delay: null) {
    transition: $css-property $time $animation-style $delay;
}

@mixin generic-font-style-italic( $style: italic) {
    font-style: $style;
    &:lang(ja-JP) {
       font-style: normal;
    }
}
$navbar-height: 6rem;