@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;
@use "./../../../../styles/screens.module" as screens;

.el-image-and-text {
    position: relative;
    width: 100%;
    background-color: colors.$el-semi-transparent-gray;

    &__container {
        @include global.span-parent(100%, 2rem);
        @include global.flex(row, center);
        min-width: 10.5rem;
        padding: 0.625rem;
        gap: 3.125rem;
        border-radius: 0.25rem;
        border: 0.125rem solid transparent;
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover:not(.el-image-and-text--selected) {
            border-color: colors.$el-semi-transparent-white;
        }

        &--selected {
            @extend .el-image-and-text__container;
            border-color: var(--spectrum-global-color-blue-500);
        }
    }

    &__image {
        @include global.span-parent(2rem, 2rem);
        margin-right: 0.25rem;
        object-fit: contain;
        filter: brightness(0) invert(1);
        transition: filter 0.2s ease;

        &--selected {
            filter: brightness(0) saturate(100%) invert(43%) sepia(99%) saturate(1720%) hue-rotate(190deg) brightness(100%) contrast(98%);
        }
    }

    &__text {
        color: var(--spectrum-global-color-gray-50);
        @include global.generic-font-style(400, 1rem, 1.5rem);
        white-space: nowrap;
        transition: color 0.2s ease;

        &--selected {
            @extend .el-image-and-text__text;
            color: var(--spectrum-global-color-blue-500);
        }
    }
}