@use "./../../../../styles/screens.module" as screens;
@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;

.create-on-demand-box {
    @include global.span-parent(15rem, 17.7rem);
    flex-direction: column;
    background: colors.$el-white 0% 0% no-repeat padding-box;
    box-shadow: 0rem 0.0625rem 0.25rem rgba(0, 0, 0, .05);
    border: 2px solid #E0E0E0;
    border-radius: 0.625rem;

    &-media {
        @include global.span-parent(100%, 100%);
        flex: 0 57%;
        border-radius: 0.625rem 0.625rem 0px 0px;
        object-fit: cover;
    }

    &__highlight {
        @include global.span-parent(15rem, 17.7rem);
        flex-direction: column;
        background: colors.$el-white 0% 0% no-repeat padding-box;
        box-shadow: 0rem 0.0625rem 0.25rem rgba(0, 0, 0, .05);
        border-radius: 0.625rem;
        border: 2px solid colors.$el-indigo-700;
        animation: fadeOutBorder 10s forwards;
    }

    &__content {
        justify-content: space-evenly;
        height: 45%;
        margin: 0rem 1.4375rem 0rem 1.25rem;
        flex-direction: column;
    }

    &__heading {
        height: 3.125rem;
        @include global.generic-font-style(900, 1.18rem, 1.5rem);
        color: colors.$el-light-black-font;
    }

    &__description {
        @include global.generic-font-style(normal, 1.0625rem, 1.3125rem);
        color: colors.$el-dark-gray;

        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media (max-width: screens.$ipad-pro-portrait) {
    .create-on-demand-box__description {
        @include global.generic-font-style(normal, 1rem, 1.5125rem);
    }
}

@keyframes fadeOutBorder {
    from {
        border-color: colors.$el-indigo-700;
    }

    to {
        border-color: transparent;
    }
}