@use "./../../../../global.module" as global;
@use "./../../../../styles/dialog.module" as dialog;
@use "./../../../../styles/colors.module" as colors;

.stock-license-dialog {
    &__container {
        @include dialog.container(32vw, fit-content);
    }

    &__heading {
        @include global.generic-font-style(bold, 1.875rem, normal);
        color: colors.$el-gray-900;
        margin-top: -5%;

        &-description {
            @include global.generic-font-style(normal, 1.15rem, normal);
            color: colors.$el-gray-700;
            font-style: oblique;
        }
    }

    &__description {
        @include global.generic-font-style(normal, 1.2rem, normal);
        margin-top: 1rem;
    }

    &__button-text {
        @include global.generic-font-style(bold, 1.2rem, normal);
        padding: 0rem 0.35rem 0rem 0.35rem;
    }
}