@use "./../../../../global.module" as global;
@use "./../../../../styles/screens.module" as screens;

#el-util-nav-container {
    @include global.span-parent(100%, 100%);

    .universal-nav-container {
        @include global.span-parent(100%, 100%);

        #universal-nav {
            padding: 0;
            margin: 0;
            border: none;

            @include global.flex(column) {
                @include global.flex-center;
            }
            @include global.span-parent(100%, 100%);

            .unav-comp-external-profile {
                @include global.flex(column) {
                    @include global.flex-center;
                }
                @include global.span-parent(100%, 100%);

                account-menu-trigger {
                    @include global.flex(column) {
                        @include global.flex-center;
                    }
                    @include global.span-parent(100%, 100%);
                }
            }
        }

        /* skipping middle classes */
        .Profile-expanded:before {
            top: 3.175rem;
            right: 1.3rem;
        }

        .utilnav-profile-wrapper .Profile-dropdown {
            top: 2.8rem;
        }
    }
}