@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;

.el-interactive-preview-action {

    &__container {
        @include global.span-parent(100%, 100%);
        @include global.flex-center;
        background-color: colors.$el-semi-transparent-gray;
        position: relative;
        border-radius: 0.5rem;
    }

    &__group {
        padding: 0.6rem;
    }

    &__swatches {
        position: relative;
        @include global.flex-center;
        top: 0;
        padding: 0.6rem 0.6rem 0.6rem 0;
        border-radius: 0.25rem;
        object-fit: cover;
        margin-left: 0.26rem;
    }
}