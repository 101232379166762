@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;
@use "./../../../../styles/screens.module" as screens;

.home-banner-container {
    width: 100%;
    height: 17.5rem;
}

.user-home-banner {
    @include global.span-parent(100%, 100%);
    border-width: 0px;
    position: absolute;
    opacity: 0;

    &__img {
        background-position: center top;
        background-size: auto 100%;
        overflow: hidden;
        max-width: 3200px;
        background-repeat: no-repeat;
        height: auto;
    }
}

.moving-banner-container {
    height: 30rem;
    margin: 2% 9.3% 0;

    &__video-banner {
        @include global.span-parent(81.4%, 100%);
        position: absolute;
        object-fit: cover;
    }

    &__overlay {
        @include global.span-parent(65%, 100%);
        position: relative;
        padding: 0 1.25rem;
        padding-top: 10rem;
        background: linear-gradient(to right, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0) 70%);
    }

    &__text-content {
        position: relative;
        color: #333; //TODO: SPECTRUM_COLOR_CHECK
    }

    &__banner-heading {
        @include global.generic-font-style(normal, 1.875rem, 2.5rem);
    }

    &__banner-cta {
        @include global.generic-font-style(bold, 1.2rem, 1.2rem);
    }

    &__product-text {
        @include global.generic-font-style(bold, 2.1875rem, 2.5rem);
    }

    &__banner-content {
        @include global.generic-font-style(bold, 1.125rem, 2.5rem);
    }

    &__creation-tag {
        position: absolute;
        bottom: 0;
        right: 9.4%;
        background-color: colors.$el-black;
        color: colors.$el-white;
        padding: 0.3125rem 0.625rem;
        border-radius: 0.3125rem;
    }
}

@media (max-width: screens.$nest-hub-max) {
    .home-banner-container {
        @include global.span-parent(100%, 20rem);
    }
}

@media (max-width: screens.$ipad-mini-landscape) {
    .home-banner-container {
        @include global.span-parent(100%, 25rem);
    }
}

@media (max-width: screens.$ipad-air-portrait) {
    .home-banner-container {
        @include global.span-parent(100%, 30rem);
    }
}

@media (max-width: screens.$iphone-SE-landscape) {
    .home-banner-container{
        @include global.span-parent(100%, 35rem);
    }
}

@media (max-width: screens.$iphone12-pro-portrait) {
    .home-banner-container {
        @include global.span-parent(100%, 40rem);
    }
}

//Matched these values with that of elive banner, so that background img appears in same position as that of HTML
@media screen and (max-device-height: 768px) {
    .user-home-banner__img {
        min-height: 200px;
    }
}

@media screen and (min-device-height: 769px) {
    .user-home-banner__img {
        min-height: 280px;
    }
}

@media (max-width: screens.$ipad-air-portrait) { 
    .moving-banner-container__overlay {
        padding-top: 4rem;
    }
}

@media screen and (max-device-width: 1366px) {
    
    .moving-banner-container__overlay {
        top: -0.2rem;
        right: 0.2rem;
    }
}

@media screen and (max-device-width: 430px) {
    
    .moving-banner-container {
        height: 21rem;

        &__banner-heading {
            @include global.generic-font-style(normal, 1.375rem, 2rem);
        }

        &__product-text {
            @include global.generic-font-style(bold, 1.875rem, 2rem);
        }

        &__banner-content {
            @include global.generic-font-style(bold, 1rem, 2rem);
        }

        &__creation-tag {
            font-size: x-small;
        }
    }
}

@media (min-width: 1525px) {
    .user-home-banner__img {
      background-size: cover;
    }
  }