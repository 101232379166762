@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;
@use "./../../../../styles/zlevel.module" as zIndex;

.interactive-banner {

    &-container {
        height: 30rem;
        margin: 2% 9.3% 0;
    }

    &__video-banner,
    &__image-banner {
        @include global.span-parent(81.4%, 100%);
        position: absolute;
        object-fit: cover;
    }

    &__left-view {
        @include global.span-parent(40%, 100%);
        position: relative;
        padding: 0 1.25rem;
        padding-top: 10rem;
        background: linear-gradient(90deg, #333, #fff);
        mix-blend-mode: multiply;
        opacity: 0.8
    }

    &__action-group {
        position: absolute;
        left: 75%;
        top: 60%;
        transform: translate(-50%, -50%);
        z-index: zIndex.$level-2;

        &:hover {
            cursor: pointer;
        }
    }

    &__text-content {
        top: 25%;
        left: 12%;
        width: 20rem;
        position: absolute;
        color: colors.$el-white;
    }

    &__banner-heading {
        @include global.generic-font-style(normal, 1.875rem, 2.5rem);
    }

    &__banner-cta {
        @include global.generic-font-style(bold, 1.2rem, 1.2rem);
    }

    &__sub-heading {
        @include global.generic-font-style(bold, 2.1875rem, 2.5rem);
        width: 31rem;
    }

    &__description-text {
        padding-top: 0.6rem;
        @include global.generic-font-style(bold, 1.125rem, 1.6rem);
    }
}