@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;
@use "./../../../../styles/screens.module" as screens;

.el-swatch {
    border: 0.125rem solid transparent;
    border-radius: 0.375rem;
    background: none;
    cursor: pointer;
    transition: border 0.2s ease;

    &:hover{
        border-color: colors.$el-semi-transparent-white;
    }

    &--selected {
        @extend .el-swatch;
        border-color: var(--spectrum-global-color-blue-500);

        &:hover {
            border-color: var(--spectrum-global-color-blue-500);
        }
    }

    &__image {
        @include global.span-parent(100%, 100%);
        object-fit: cover;
        border-radius: 0.25rem;
    }
}