@use "./../../../../global.module" as global;
@use "./../../../../styles/colors.module" as colors;
@use "./../../../../styles/screens.module" as screens;

.basic-background {
    &-image {
        max-width: 100%;
        max-height: 100%;
        @include global.flex-center;
    }

    &-card {
        @include global.flex-center;
        background: transparent;
        cursor: pointer;
        box-sizing: border-box;
        padding: 0.1825rem;

        &-selected {
            @include global.flex-center;
            background: transparent;
            cursor: pointer;
            box-sizing: border-box;
            padding: 0.1825rem;
            border: 0.1825rem solid colors.$el-purple-800;
            border-radius: 0.625rem;
        }
    }

    &-scroll {
        height: 92%;
        scrollbar-gutter: stable;
    }
}

@media (max-width: screens.$nest-hub-max) {
    .basic-background-scroll {
        height: 87%;
        scrollbar-gutter: stable;
    }
}

.basic-panel-box {
    height: 100%;
    background-color: white
}

.basic-panel__content-box {
    max-height: 100%;
    padding: 0 5%;

    &-divider {
        width: 100%;
        border-top: 3px solid colors.$el-gray-300;
        margin: 1.8rem 0 1rem 0;
    }
}